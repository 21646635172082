import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React, { useContext, useState } from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import StoreContext from '../context/StoreContext'
import Arrow from '../images/mobile/icons/arrow.svg'
import {
  BodyText,
  ContentPadding,
  CreamBackground,
  Heading,
  ImageContainer,
  SplitContainer,
  SplitItem,
  StaticContainer,
  StyledImage,
  TopMagin,
} from '../styles/static.js'

const EmailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 0 24px;
`

const ContactContainerDesktop = styled.div`
  width: calc(100%);
  max-width: 466px;
  display: inline-block;
  background-color: transparent;
  border-radius: 5px;
  @media (max-width: 1025px) {
    margin-bottom: 6px;
  }
  @media (min-width: 1025px) {
    width: 400px;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: space-between;
  }
`

const ContactText = styled.div`
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 12px;
`

const EmailImput = styled.input`
  margin: 8px 0;
  line-height: 32px;
  width: 95%;
  border: none;
  font-size: 16px;
  background: transparent;
  outline-width: 0;
`
const Failed = styled.span`
  display: block;
  color: red;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
`

const WhyPage = ({ data, location }) => {
  const stack = data.Comfort.childImageSharp.gatsbyImageData
  let [email, setEmail] = useState('')
  let [sent, setSent] = useState(false)
  let [emailValid, setEmailValid] = useState(true)

  let context = useContext(StoreContext)
  let { currency } = context
  let au = currency === 'au'

  const handleChange = (e) => {
    setEmail(e.target.value)
    if (!emailValid) {
      validateEmail(e.target.value)
    }
  }
  const handleEnter = async () => {
    if (validateEmail(email)) {
      let formData = new FormData()
      formData.append('g', 'Jjx9RS')
      formData.append(
        '$fields',
        '$source,$email,$consent_method,$consent_form_id,$consent_form_version'
      )

      if (currency == 'au') {
        formData.append('$consent_form_id', 'KHUf42')
        formData.append('$list_fields', undefined)
        formData.append('$timezone_offset', 11)
        formData.append('$source', 'Mobile Signup Footer')
        formData.append('$email', email)
        formData.append('$consent_method', 'Klaviyo Form')
      } else {
        formData.append('$consent_form_id', 'UqW7wG')
        formData.append('$list_fields', undefined)
        formData.append('$timezone_offset', 11)
        formData.append('$source', 'Us Signups')
        formData.append('$email', email)
        formData.append('$consent_method', 'Klaviyo Form')
      }

      formData.append('$consent_form_version', 659788)

      await fetch(`https://a.klaviyo.com/ajax/subscriptions/subscribe`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: formData,
      })
      setEmail('')
      setSent(true)
    }
  }
  const add = (event) => {
    if (event.keyCode === 13) {
      handleEnter()
    }
  }
  const validateEmail = (value) => {
    const regex =
      /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/ // eslint-disable-line
    if (value.length > 0 && !regex.test(value)) {
      setEmailValid(false)
      return false
    }
    setEmailValid(true)
    return true
  }
  return (
    <Layout location={location.pathname}>
      <SEO
        title={'Community'}
        description={'Be a part of the community. Sign up to our mailing list.'}
      />
      <StaticContainer>
        <TopMagin />
        <CreamBackground>
          <ContentPadding>
            <SplitContainer center={true}>
              <SplitItem>
                <ImageContainer>
                  <StyledImage image={stack} />
                </ImageContainer>
              </SplitItem>
              <SplitItem>
                <Heading main={true}> Be a part of the community. </Heading>
                <br />
                <BodyText>
                  <ContactContainerDesktop>
                    <ContactText> Sign up to our mailing list. </ContactText>
                    {!emailValid && <Failed> Email is not valid</Failed>}
                    {sent ? (
                      <>
                        {' '}
                        <br /> Thanks for signing up{' '}
                      </>
                    ) : (
                      <EmailContainer>
                        <EmailImput
                          placeholder={'your@email.com'}
                          value={email}
                          onKeyDown={add}
                          onChange={handleChange}
                        />
                        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role, jsx-a11y/control-has-associated-label */}
                        <img
                          alt=""
                          style={{
                            cursor: 'pointer',
                          }}
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                          role="button"
                          tabIndex={0}
                          onKeyDown={handleEnter}
                          onClick={handleEnter}
                          src={Arrow}
                        />
                      </EmailContainer>
                    )}
                  </ContactContainerDesktop>
                </BodyText>
              </SplitItem>
            </SplitContainer>
          </ContentPadding>
        </CreamBackground>
      </StaticContainer>
    </Layout>
  )
}

export default WhyPage

export const query = graphql`
  {
    Comfort: file(relativePath: { eq: "buy/comfort.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
